// import React, { useState } from "react";
// import { Modal, Button, Form } from "react-bootstrap";

// const initialData = {
//     name: "Oswald",
//     title: "Security Department",
//     children: [],
//     // siblings: []
// };

// export default function CreateTeam() {
//     const [teamName, setTeamName] = useState("");
//     const [showModal, setShowModal] = useState(false);
//     const [selectedRole, setSelectedRole] = useState("Dev Manager");
//     const [teamData, setTeamData] = useState(initialData);
//     const [currentNode, setCurrentNode] = useState(null);
//     const [addAsSibling, setAddAsSibling] = useState(false);

//     const roles = ["Dev Manager", "Senior Software Developer", "Software Developer", "ML Co-op"];

//     const handleAddMember = (node, sibling = false) => {
//         setCurrentNode(node);
//         setAddAsSibling(sibling);
//         setShowModal(true);
//     };

//     const handleSaveMember = () => {
//         const newMember = { name: "New Member", title: selectedRole, children: [], siblings: [] };

//         const addMemberToTree = (node, parent = null) => {
//             if (node === currentNode) {
//                 if (addAsSibling && parent === null) {
//                     return { ...node, siblings: [...node.siblings, newMember] };
//                 } else {
//                     return { ...node, children: [...node.children, newMember] };
//                 }
//             }
//             return { ...node, children: node.children.map(child => addMemberToTree(child, node)) };
//         };

//         setTeamData(prev => addMemberToTree(prev));
//         setShowModal(false);
//     };

//     const getNodeColor = (level) => {
//         const colors = ["#007bff", "#28a745", "#dc3545", "#17a2b8"];
//         return colors[level % colors.length];
//     };

//     const renderTree = (node, parent = null, level = 0) => {
//         return (
//             <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "16px", position: "relative" }}>
//                 {parent && (
//                     <div style={{ position: "absolute", top: "-16px", left: "50%", width: "2px", height: "16px", backgroundColor: "#000" }}></div>
//                 )}
//                 <div className="mb-2" style={{ padding: "12px", color: "#fff", borderRadius: "8px", textAlign: "center", position: "relative", backgroundColor: getNodeColor(level) }}>
//                     <div style={{ fontWeight: "bold" }}>{node.name}</div>
//                     <div style={{ color: "#f8f9fa" }}>{node.title}</div>
//                     <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
//                         <Button variant="outline-light" size="sm" onClick={() => handleAddMember(node)}>➕</Button>
//                         {/* {node === teamData && (
//                             <Button variant="outline-secondary" size="sm" style={{ marginLeft: "8px" }} onClick={() => handleAddMember(node, true)}>➕ Add Sibling</Button>
//                         )} */}
//                     </div>
//                 </div>
//                 <div style={{ display: "flex", marginTop: "12px", position: "relative" }}>
//                     {node.children.length > 0 && (
//                         <div style={{ position: "absolute", top: "-12px", left: "50%", transform: "translateX(-50%)", borderTop: "2px solid #000", width: "100%", marginTop: "12px" }}></div>
//                     )}
//                     {node.children.map((child, index) => (
//                         <div key={index} style={{ position: "relative" }}>{renderTree(child, node, level + 1)}</div>
//                     ))}
//                 </div>
//                 {/* {node === teamData && node.siblings.length > 0 && (
//                     <div style={{ display: "flex", marginTop: "16px", gap: "12px", borderTop: "2px solid #000", paddingTop: "12px" }}>
//                         {node.siblings.map((sibling, index) => (
//                             <div key={index}>{renderTree(sibling, null, level)}</div>
//                         ))}
//                     </div>
//                 )} */}
//             </div>
//         );
//     };

//     return (
//         <div className="container mt-4">
//             <h2 className="fw-bold">Manage Team</h2>
//             <Form.Control
//                 type="text"
//                 placeholder="Enter team name..."
//                 value={teamName}
//                 onChange={(e) => setTeamName(e.target.value)}
//                 className="mt-2 w-50"
//             />
//             <div style={{ display: "flex", justifyContent: "center", marginTop: "16px", position: "relative" }}>{renderTree(teamData)}</div>
//             <div className="w-100 d-flex justify-content-end">
//                 <Button className="mt-4" variant="success">Save Team</Button>
//             </div>
//             <Modal show={showModal} onHide={() => setShowModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Add Team Member</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form>
//                         <Form.Group>
//                             <Form.Label>Select Role</Form.Label>
//                             <Form.Select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
//                                 {roles.map((role, index) => (
//                                     <option key={index} value={role}>{role}</option>
//                                 ))}
//                             </Form.Select>
//                         </Form.Group>
//                     </Form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
//                     <Button variant="primary" onClick={handleSaveMember}>Save</Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }
import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const initialData = {
    name: "Oswald",
    title: "Security Department",
    children: [],
    siblings: [],
};

export default function CreateTeam() {
    const [teamName, setTeamName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState("Dev Manager");
    const [teamData, setTeamData] = useState(initialData);
    const [currentNode, setCurrentNode] = useState(null);
    const [addAsSibling, setAddAsSibling] = useState(false);

    const roles = ["Dev Manager", "Senior Software Developer", "Software Developer", "ML Co-op"];

    const handleAddMember = (node, sibling = false) => {
        setCurrentNode(node);
        setAddAsSibling(sibling);
        setShowModal(true);
    };

    const handleSaveMember = () => {
        const newMember = { name: "New Member", title: selectedRole, children: [], siblings: [] };

        if (addAsSibling) {
            // Add sibling at the same level
            setTeamData(prev => ({
                ...prev,
                siblings: [...prev.siblings, newMember],
            }));
        } else {
            // Add child to the current node
            const addMemberToTree = (node) => {
                if (node === currentNode) {
                    return { ...node, children: [...node.children, newMember] };
                }
                return { ...node, children: node.children.map(child => addMemberToTree(child)) };
            };
            setTeamData(prev => addMemberToTree(prev));
        }

        setShowModal(false);
    };

    const handleRemoveNode = (nodeToRemove) => {
        const removeNode = (node) => {
            if (node === nodeToRemove) {
                return null;
            }
            return {
                ...node,
                children: node.children.filter(child => child !== nodeToRemove).map(removeNode),
                siblings: node.siblings.filter(sibling => sibling !== nodeToRemove),
            };
        };
        setTeamData(prev => removeNode(prev));
    };

    const getNodeColor = (level) => {
        const colors = ["#7B61FF", "#29CC6A", "#FC5555", "#17a2b8"];
        return colors[level % colors.length];
    };

    const renderTree = (node, level = 0, isRoot = false) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "16px", position: "relative" }}>
                {level > 0 && (
                    <div style={{ position: "absolute", top: "-16px", left: "50%", width: "2px", height: "16px", backgroundColor: "#000" }}></div>
                )}
                <div className="mb-2" style={{ padding: "17px", color: "#fff", borderRadius: "8px", textAlign: "center", backgroundColor: getNodeColor(level), position: "relative" }}>
                    <div style={{ fontWeight: "bold" }}>{node.name}</div>
                    <div style={{ color: "#f8f9fa" }}>{node.title}</div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                        <Button variant="outline-light" size="sm" onClick={() => handleAddMember(node)}>Add Child</Button>
                        {node === teamData && (
                            <Button variant="outline-light" size="sm" style={{ marginLeft: "8px" }} onClick={() => handleAddMember(node, true)}>
                                {/* ➕  */}
                                Add Sibling</Button>
                        )}
                    </div>
                    {!isRoot && (
                        <Button
                            // variant="outline-danger"
                            className="fs-6 p-0 border-none"
                            style={{ position: "absolute", top: "0px", right: "0px", backgroundColor: "transparent", border: "none" }}
                            onClick={() => handleRemoveNode(node)}
                        >
                            ❌

                        </Button>
                    )}
                </div>
                <div style={{
                    display: "flex", marginTop: "12px", position: "relative",
                    borderTop: "1px solid black"
                }}>
                    {node.children.map((child, index) => (
                        <div key={index} style={{ position: "relative" }}>{renderTree(child, level + 1)}</div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSiblings = (siblings, level) => {
        return siblings.map((sibling, index) => (
            <div key={index} style={{ marginLeft: "16px" }}>{renderTree(sibling, level)}</div>
        ));
    };

    return (
        <div className="container mt-4">
            <h2 className="fw-bold">Manage Team</h2>
            <div className="d-flex">

                <Form.Control
                    type="text"
                    placeholder="Enter team name..."
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                    className="mt-2 w-50"
                />
                <div className="w-100 d-flex justify-content-end">
                    <Button className="mt-4" variant="success">Save Team</Button>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                {renderTree(teamData, 0, true)}
                {renderSiblings(teamData.siblings, 0)}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Team Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                                {roles.map((role, index) => (
                                    <option key={index} value={role}>{role}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveMember}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import Calendar from 'react-calendar';
import { FaSearch, FaCheckCircle, FaTimesCircle, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import axios from 'axios';
// Register the required Chart.js elements
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement, // Register this
    Title,
    Tooltip,
    Legend
);



const Dashboard = () => {
    const [dayWiseStats, setDayWiseStats] = useState([]);
    const [monthWiseStats, setMonthWiseStats] = useState([]);
    const [totalWeekEntries, setTotalWeekEntries] = useState(0);
    const [totalMonthEntries, setTotalMonthEntries] = useState(0);
    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async () => {
        try {
            const res = await axios.get("http://api.avessecurity.com:6378/api/gatepassForm/GatePassFormData");
            setDayWiseStats(res.data.dayWiseStats);
            setMonthWiseStats(res.data.monthWiseStats);
            const weekTotal = res.data.dayWiseStats.reduce((sum, day) => sum + day.count, 0);
            const monthTotal = res.data.monthWiseStats.reduce((sum, month) => sum + month.count, 0);

            setTotalWeekEntries(weekTotal);
            setTotalMonthEntries(monthTotal);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [datastaffall, setDatastaffall] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    // Fetch data from the API
    const fetchChartData = async () => {
        try {
            const response = await axios.get("https://api.avessecurity.com/api/monthlyData/get-formData");
            const { labels, data } = response.data;

            // Prepare dynamic datasets
            const datasets = [];
            Object.keys(data).forEach((module) => {
                const roles = data[module];
                Object.keys(roles).forEach((role) => {
                    datasets.push({
                        label: `${module} (${role})`,
                        data: roles[role],
                        borderColor: getRandomColor(),
                        backgroundColor: getRandomColor(0.2),
                        tension: 0.4,
                    });
                });
            });

            setChartData({ labels, datasets });
        } catch (error) {
            console.error("Error fetching chart data:", error);
        }
    };

    // Fetch staff data
    const fetchStaffData = async () => {
        try {
            const response = await axios.get(`your_url/admin/Managpermission/index?page=1`);
            setDatastaffall(response.data); // Update this according to your API response
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    useEffect(() => {
        fetchChartData();
        fetchStaffData();
    }, []);

    // Helper function to generate random colors
    const getRandomColor = (alpha = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/edit-account');
    };
    const handleNavigateform = () => {
        navigate('/formsubmit');
    };
    const handleNavigateonlinestauts = () => {
        navigate('/onlinestatus');
    };
    const handleNavigateloginhistory = () => {
        navigate('/loginhistory');
    };
    const handleNavigatenotificationhistory = () => {
        navigate('/notificationhistory');
    };
    const handleNavigatepasses = () => {
        navigate('/passes');
    };
    useEffect(() => {

    }, [])
    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Guard',
                data: [150, 180, 120, 200, 300, 250, 400, 380, 350, 280, 230, 200],
                borderColor: '#007bff',
                backgroundColor: 'rgba(0, 123, 255, 0.2)',
                tension: 0.4,
            },
            {
                label: 'Supervisor',
                data: [100, 130, 170, 200, 220, 280, 300, 320, 290, 250, 220, 180],
                borderColor: '#28a745',
                backgroundColor: 'rgba(40, 167, 69, 0.2)',
                tension: 0.4,
            },
            {
                label: 'Admin',
                data: [50, 90, 80, 120, 140, 160, 200, 210, 190, 170, 150, 120],
                borderColor: '#dc3545',
                backgroundColor: 'rgba(220, 53, 69, 0.2)',
                tension: 0.4,
            },
        ],
    };

    const onlineStatusData = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: 'Online',
                data: [20, 25, 30, 35, 40, 45, 50],
                backgroundColor: '#4caf50',
            },
            {
                label: 'Offline',
                data: [15, 20, 25, 30, 35, 40, 45],
                backgroundColor: '#f44336',
            },
        ],
    };
    const data = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: "Last Month",
                data: [10, 20, 15, 25, 20, 30, 40],
                borderColor: "#4A90E2",
                backgroundColor: "rgba(74, 144, 226, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#4A90E2",
            },
            {
                label: "This Month",
                data: [5, 15, 10, 20, 15, 25, 50],
                borderColor: "#00C48C",
                backgroundColor: "rgba(0, 196, 140, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#00C48C",
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Dashboard Metrics' },
        },
    };
    const monthWiseData = {
        labels: monthWiseStats.map((m) => m.month),
        datasets: [
            {
                label: "Gate Pass Entries (Month-wise)",
                data: monthWiseStats.map((m) => m.count),
                borderColor: "#00C48C",
                backgroundColor: "rgba(0, 196, 140, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#00C48C",
            },
        ],
    };
    return (
        <div className="App">
            <div className="dashboard-wrapper">
                <div className="dashboard-row">
                    <div className="dashboard-card total-metrics" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                        <div className="label-box">Total</div>
                        <div className="metrics-circle total-circle mt-5">
                            <h2>1000</h2>
                        </div>
                    </div>
                    <div className="dashboard-card online-metrics" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                        <div className="label-box">Online</div>
                        <div className="metrics-circle online-circle mt-5">
                            <h2>900</h2>
                        </div>
                    </div>
                    <div className="dashboard-card offline-metrics" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                        <div className="label-box">Offline</div>
                        <div className="metrics-circle offline-circle mt-5">
                            <h2>100</h2>
                        </div>
                    </div>
                </div>
                <div className="dashboard-card" onClick={handleNavigateform} style={{ width: '100%', cursor: 'pointer' }}>
                    <h5>Total Form (Monthly)</h5>
                    {/* <Line data={lineChartData} options={chartOptions} /> */}
                    <Line
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: { position: "top" },
                                title: { display: true, text: "Dashboard Metrics" },
                            },
                        }}
                    />
                </div>
                <div className="dashboard-container">
                    <div className="graph-row">
                        <div className="col-4 dashboard-section" onClick={handleNavigateonlinestauts} style={{ width: '100%', cursor: 'pointer' }}>
                            <h5>Online Status</h5>
                            <Bar data={onlineStatusData} options={chartOptions} />
                        </div>
                        <div className="col-4 dashboard-section" onClick={handleNavigatepasses} style={{ width: '100%', cursor: 'pointer' }}>
                            <div className="gatepassdash">
                                <h5>GATE PASS</h5>
                                <div className="gatepassdashboard-chart-container">
                                    <Line data={monthWiseData} options={chartOptions} />
                                </div>
                                <div className="gatepassdashboard-legend">
                                    {/* <div className="gatepassdashboard-legend-item">
                                        <span className="gatepassdashboard-dot" style={{ backgroundColor: "#4A90E2" }}></span>
                                        Last Month
                                        <span className="gatepassdashboard-value">86</span>
                                    </div> */}
                                    <div className="gatepassdashboard-legend-item">
                                        <span className="gatepassdashboard-dot" style={{ backgroundColor: "#00C48C" }}></span>
                                        This Month
                                        <span className="gatepassdashboard-value">{totalMonthEntries}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 dashboard-section">
                            <h5>Calendar</h5>
                            <Calendar className="custom-calendar" />
                        </div>

                    </div>
                </div>
                <div className="dashboard-container mt-4 w-100">
                    <div className="row">
                        {/* Login History Section */}
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="loginhistory-card p-4" onClick={handleNavigateloginhistory} style={{ width: '100%', cursor: 'pointer' }}>
                                <div className="loginhistory-header mb-3">
                                    <h5 className="loginhistory-title">LOGIN HISTORY</h5>
                                    <button className="loginhistory-see-all-btn">See all</button>
                                </div>
                                <div className="loginhistory-search mb-3">
                                    <form className="d-flex">
                                        <div className="position-relative w-100">

                                            <FaSearch
                                                className="position-absolute text-muted"
                                                style={{
                                                    top: "50%",
                                                    left: "9px",
                                                    transform: "translateY(-50%)",
                                                    pointerEvents: "none", // Makes the icon unclickable
                                                }}
                                            />
                                            <input
                                                className="form-control bg-light ps-4"
                                                type="text"
                                                placeholder="Search here..."

                                            />
                                        </div>
                                    </form>
                                </div>
                                <table className="loginhistory-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>IN/OUT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>@maddison_c21</td>
                                            <td>30/10/2024</td>
                                            <td>18:00</td>
                                            <td>
                                                <div className="status-line status-out"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>@karl.will02</td>
                                            <td>30/10/2024</td>
                                            <td>18:00</td>
                                            <td>
                                                <div className="status-line status-in"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>@andreea.lz</td>
                                            <td>30/10/2024</td>
                                            <td>18:00</td>
                                            <td>
                                                <div className="status-line status-out"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>@abraham47.y</td>
                                            <td>30/10/2024</td>
                                            <td>18:00</td>
                                            <td>
                                                <div className="status-line status-in"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>@simmmple.web</td>
                                            <td>30/10/2024</td>
                                            <td>18:00</td>
                                            <td>
                                                <div className="status-line status-in"></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Notification Section */}
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="dashnotify-card p-4" onClick={handleNavigatenotificationhistory} style={{ width: '100%', cursor: 'pointer' }}>
                                <div className="dashnotify-header mb-3">
                                    <h5 className="dashnotify-title">NOTIFICATION</h5>
                                    <button className="dashnotify-see-all-btn">See all</button>
                                </div>
                                <table className="dashnotify-table">
                                    <thead>
                                        <tr>
                                            <th>Sender</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Receiver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Horizon UI PRO</td>
                                            <td>
                                                <div className="status-content">
                                                    <FaCheckCircle className="icon-approved" />
                                                    <span>Approved</span>
                                                </div>
                                            </td>
                                            <td>18 Apr 2021</td>
                                            <td>18:00</td>
                                            <td>Horizon UI PRO</td>
                                        </tr>
                                        <tr>
                                            <td>Horizon UI Free</td>
                                            <td>
                                                <div className="status-content">
                                                    <FaTimesCircle className="icon-declined" />
                                                    <span>Decline</span>
                                                </div>
                                            </td>
                                            <td>18 Apr 2021</td>
                                            <td>18:00</td>
                                            <td>Horizon UI PRO</td>
                                        </tr>
                                        <tr>
                                            <td>Marketplace</td>
                                            <td>
                                                <div className="status-content">
                                                    <FaExclamationTriangle className="icon-error" />
                                                    <span>Error</span>
                                                </div>
                                            </td>
                                            <td>20 May 2021</td>
                                            <td>18:00</td>
                                            <td>Horizon UI PRO</td>
                                        </tr>
                                        <tr>
                                            <td>Weekly Updates</td>
                                            <td>
                                                <div className="status-content">
                                                    <FaInfoCircle className="icon-info" />
                                                    <span>Info</span>
                                                </div>
                                            </td>
                                            <td>12 Jul 2021</td>
                                            <td>18:00</td>
                                            <td>Horizon UI PRO</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { FaBell, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Heade.css";
import { jwtDecode } from 'jwt-decode'
const Header = () => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  // const token = localStorage.getItem('access_token')
  const [data, setData] = useState()
  useEffect(() => {
    const token = localStorage.getItem("access_token"); // Replace with your token's key
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setData(decodedToken)
        console.log("Decoded Payload:", decodedToken);
      } catch (error) {
        console.error("Error decoding token:", error.message);
      }
    } else {
      console.log("No token found!");
    }
  }, []);
  const handleSignout = () => {
    localStorage.clear();
    setData(null); // Clear user data
    navigate("/", { replace: true });
    window.location.reload(); // Force re-render
  };




  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <a className="navbar-brand-dash fs-1 fw-bold text-dark" href="#">
            Dashboard
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ms-5">
                <form className="d-flex">
                  <div className="position-relative w-100">
                    <FaSearch
                      className="position-absolute text-muted"
                      style={{
                        top: "50%",
                        left: "9px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Makes the icon unclickable
                      }}
                    />
                    <input
                      className="form-control bg-light ps-4"
                      type="text"
                      placeholder="Search here..."
                    />
                  </div>
                </form>
              </li>

              <li className="nav-item ms-5">
                {/* <select
                  className="form-select border-0"
                  aria-label="Default select example"
                >
                  <option selected>Eng (US)</option>
                  <option value="1">Tamil (India)</option>
                </select> */}
              </li>
            </ul>
            <form className="d-flex  align-items-center">
              <FaBell className="ms-5 fs-3" />

              <div className="dropdown  border-0 ms-5 ml-5 d-flex flex-column align-items-center">
                <button
                  className="btn  border-0 pb-0  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {username}
                </button>
                <span className="text-secondary fs-6">{data?.role}</span>
                <ul className="dropdown-menu p-0" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <button
                      className="w-100 border-1 h-100 bg-light px-1 py-2"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default anchor behavior
                        handleSignout();
                      }}
                    >
                      Signout
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

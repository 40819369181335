import React, { useState, useEffect } from "react";
import profile_image from "../../src/components/Images/profile_image.svg";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

function Edit_Staff() {
    const { id } = useParams();
    const location = useLocation();
    const userData = location?.state?.userData;
    console.log("User Data:", userData);

    const [roles, setRoles] = useState([]); // State to store role options
    const [formData, setFormData] = useState({
        UserImage: null,
        FirstName: "",
        LastName: "",
        username: "",
        Gender: "",
        Department: "",
        Designation: "",
        Occupation: "",
        Race: "",
        PassportNumber: "",
        StaffIdNumber: "",
        CompanyName: "",
        EmailId: "",
        ContactNumber: "",
        NRICNumber: "",
        role: "",
        Address: "",
    });

    const [errors, setErrors] = useState({});
    const [previewImage, setPreviewImage] = useState(null);

    // Fetch roles from API
    useEffect(() => {
        axios.get("http://api.avessecurity.com/api/Roles/getRole")
            .then((response) => {
                if (response.data?.Roles) {
                    setRoles(response.data.Roles);
                }
            })
            .catch((error) => console.error("Error fetching roles:", error));
    }, []);

    // Populate form with existing user data
    useEffect(() => {
        if (userData) {
            setFormData((prevData) => ({
                ...prevData,
                ...userData,
                UserImage: userData.UserImage ? userData.UserImage : null,
                role: userData.role?._id || "" // Ensure role is set
            }));
            setPreviewImage(userData.UserImage ? "https://api.avessecurity.com" + "/" + userData.UserImage[0] : profile_image);
        }
    }, [userData, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update role field correctly
        if (name === "role") {
            setFormData((prevData) => ({
                ...prevData,
                role: value // Store role ID
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                UserImage: file,
            }));
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key !== "__v") {
                if (key === "UserImage" && formData.UserImage instanceof File) {
                    formDataToSend.append("UserImage", formData.UserImage);
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
        });
        formDataToSend.append("__v", formData.__v + 1 || 0);

        try {
            const response = await axios.post(
                `https://api.avessecurity.com/api/users/update/${id}`,
                formDataToSend
            );
            console.log("Success:", response.data);
            alert("Account Updated Successfully!");
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            alert("Account Update Error!", error.message);
        }
    };

    const validateForm = () => {
        let newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== "UserImage") {
                newErrors[key] = `${key} is required`;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <div className="container-fluid">
            <section className="section">
                <div className="card mt-4 p-4">
                    <h3>ACCOUNT MODIFY</h3>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 text-center">
                            <input type="file" name="UserImage" id="upload-image" onChange={handleImageChange} className="form-control d-none" />
                            <label htmlFor="upload-image" className="d-block text-center"
                                style={{
                                    cursor: "pointer",
                                    backgroundImage: `url(${previewImage})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "150px",
                                    height: "150px",
                                    borderRadius: "50%",
                                    margin: "0 auto",
                                    border: "2px solid #ddd"
                                }}
                            >
                            </label>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                {["FirstName", "LastName", "username", "Gender", "Department", "Designation", "Occupation", "Race"]
                                    .map((key) => (
                                        <div key={key} className="mb-3">
                                            <label className="fw-bold">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                                            <input
                                                type="text"
                                                name={key}
                                                value={formData[key]}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                            {errors[key] && <small className="text-danger">{errors[key]}</small>}
                                        </div>
                                    ))}
                            </div>

                            <div className="col-md-6">
                                {["PassportNumber", "StaffIdNumber", "CompanyName", "EmailId", "ContactNumber", "NRICNumber", "Address"]
                                    .map((key) => (
                                        <div key={key} className="mb-3">
                                            <label className="fw-bold">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                                            <input
                                                type="text"
                                                name={key}
                                                value={formData[key]}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                            {errors[key] && <small className="text-danger">{errors[key]}</small>}
                                        </div>
                                    ))}

                                {/* Role Dropdown */}
                                <div className="mb-3">
                                    <label className="fw-bold">Role</label>
                                    <select
                                        name="role"
                                        value={formData.role}  // Now correctly stores role._id
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="">Select a Role</option>
                                        {roles.map((role) => (
                                            <option key={role._id} value={role._id}>
                                                {role.name}
                                            </option>
                                        ))}
                                    </select>

                                    {errors.role && <small className="text-danger">{errors.role}</small>}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center w-100">
                            <button type="submit" className="btn btn-dark w-25">Update Changes</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default Edit_Staff;



import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Manageallstaffs from './pages/Manageallstaffs';
import Login from './layouts/Login';
import Signup from './layouts/Signup';
import Modules from './pages/Modules';
import Manageroles from './pages/Manageroles';
import ManagePermission from './pages/Managepermission';
import AddStaff from './pages/AddStaff';
import AddRole from './pages/AddRole';
import Activities from './pages/Activities';
import Calendarreportpage from './pages/Calendarreportpage';
import ProtectedRoute from './components/ProtectedRoutes';
import Edit_Staff from './pages/EditStaff';
import EditManagerole from './pages/EditManagerole';
import EditStaff from './pages/EditStaff';
import EditAccount from './pages/EditAccount';
import FormSubmit from './pages/FormSubmit';
import OnlineStatus from './pages/OnlineStatus';
import GatePass from './pages/GatePass';
import ManageTeams from './pages/ManageTeams';
import CreateTeam from './pages/CreateTeam';


const App = () => {
  const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    setLoginData(token);
  }, [localStorage.getItem("access_token")]);

  const handleLogin = (token) => {
    localStorage.setItem("access_token", token);
    setLoginData(token);
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        {!loginData ? (
          <>
            <Route path="/" element={<Login onLogin={handleLogin} />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
          </>
        ) : (
          // Protected Routes
          <>
            <Route
              path="/*"
              element={
                <Sidebar>
                  <Header />
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/manage-staffs"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Manageallstaffs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/modules"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Modules />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/manage-role"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Manageroles />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Manage-Permissions"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <ManagePermission />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/add-staff"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <AddStaff />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/add-role"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <AddRole />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/activity"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Activities />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/calendarreport"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Calendarreportpage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/formsubmit"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <FormSubmit />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/onlinestatus"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <OnlineStatus />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/passes"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <GatePass />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/teams"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <ManageTeams />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/create-team"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <CreateTeam />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/edit-staff/:id"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <Edit_Staff />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/edit-roles/:id"
                      element={
                        <ProtectedRoute loginData={loginData}>
                          <EditManagerole />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/edit-account" element={
                      <EditAccount />
                    } />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                  </Routes>
                </Sidebar>
              }
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import edititem from "../../src/components/Images/edit.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function ManagePermission(props) {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 2, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 3, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 4, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },]);

    const fetchDatanextprestaffall = () => {
        axios
            .get(
                `your_url/admin/Managpermission/index?page=$`,

            )
            .then((res) => {
                // setDatastaffall(videosData);                 
            })
            .catch((err) => {
                console.log(err);
            });
    };



    return (
        <>

            <div className=" m-0 p-0">
                <div className="container-fluid">
                    <section className="section mt-4">
                        <div className="card">
                            <div className="col-lg-12 row py-3">
                                <div className="col-5 col-sm-5 col-lg-5">
                                    <h3>MANAGE ROLES</h3>
                                </div>
                            </div>

                            <div className="col-lg-12 ">
                                <div className="card">

                                    <div className="p-2 text-start iq-card-body">
                                        <Table>
                                            <thead className="p-1">
                                                <tr className="title">

                                                    <th>#</th>
                                                    <th>NAMES </th>
                                                    <th>STAFF ID</th>
                                                    <th>DEPARTMENT</th>
                                                    <th>DESTIGNATION</th>
                                                    <th>ROLES</th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </thead>
                                            {datastaffall?.map((item, key) => (
                                                <tbody>
                                                    <tr key={item?.id}>
                                                        <td>
                                                            <div>{item?.id}</div>
                                                        </td>

                                                        <td> {item?.name}</td>
                                                        <td> {item?.staff_id}</td>
                                                        <td> {item?.department}</td>
                                                        <td> {item?.destignation}</td>
                                                        <td> {item?.role}</td>

                                                        <td>
                                                            <div className="editdropdown">
                                                                <div className="editdropdown-staff">
                                                                    <button
                                                                        type="button"
                                                                        className="edit border-0"
                                                                        onClick={() => window.location.href = "/edit-permission/" + item?.id}
                                                                    >
                                                                        <span>
                                                                            <img
                                                                                src={edititem}
                                                                                alt="flogo"
                                                                                width={20}
                                                                                height={20}
                                                                            />

                                                                        </span>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </Table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </>
    );
}

export default ManagePermission;

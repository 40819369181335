import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { FaSearch, FaCheckCircle } from "react-icons/fa";

function EditAccount(props) {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
        { id: 2, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "offline" },
        { id: 3, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "deactivate" },
        { id: 4, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
    ]);

    const fetchDatanextprestaffall = () => {
        axios
            .get(
                `your_url/admin/editaccount/index?page=$`,
            )
            .then((res) => {
                // setDatastaffall(videosData);                 
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchDatanextprestaffall();
    }, []);

    const renderStatus = (status) => {
        let bgColor = "";
        let textColor = "text-black";

        switch (status) {
            case "online":
                bgColor = "bg-success";
                break;
            case "offline":
                bgColor = "bg-danger";
                break;
            case "deactivate":
                bgColor = "bg-warning";
                break;
            default:
                bgColor = "bg-secondary";
        }

        return (
            <span
                className={`d-inline-flex align-items-center px-3 py-1 rounded-pill ${bgColor} ${textColor}`}>
                <FaCheckCircle className="me-2" /> {/* Icon appears first */}
                {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
        );
    };

    return (
        <div className="m-0 p-0">
            <div className="container-fluid">
                <section className="section mt-4">
                    <div className="dashboard-row">
                        <div className="dashboard-card total-metrics">
                            <div className="label-box">Total</div>
                            <div className="metrics-circle total-circle mt-5">
                                <h2>1000</h2>
                            </div>
                        </div>
                        <div className="dashboard-card online-metrics">
                            <div className="label-box">Online</div>
                            <div className="metrics-circle online-circle mt-5">
                                <h2>900</h2>
                            </div>
                        </div>
                        <div className="dashboard-card offline-metrics">
                            <div className="label-box">Offline</div>
                            <div className="metrics-circle offline-circle mt-5">
                                <h2>100</h2>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="col-lg-12 row py-3">
                            <div className="col-6 col-sm-6 col-lg-6">
                                <h3> ACCOUNT </h3>
                            </div>

                            <div className="col-6 col-sm-6 col-lg-6 text-end">
                                <form className="d-flex justify-content-end">
                                    <div className="position-relative w-75">
                                        <FaSearch
                                            className="position-absolute text-muted"
                                            style={{
                                                top: "50%",
                                                left: "9px",
                                                transform: "translateY(-50%)",
                                                pointerEvents: "none", // Makes the icon unclickable
                                            }}
                                        />
                                        <input
                                            className="form-control bg-light ps-4"
                                            type="text"
                                            placeholder="Search here..."
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="p-2 text-start iq-card-body">
                                    <Table>
                                        <thead className="p-1">
                                            <tr className="title">
                                                <th>#</th>
                                                <th>NAMES</th>
                                                <th>STAFF ID</th>
                                                <th>DEPARTMENT</th>
                                                <th>DESIGNATION</th>
                                                <th>ROLES</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datastaffall.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.staff_id}</td>
                                                    <td>{item.department}</td>
                                                    <td>{item.destignation}</td>
                                                    <td>{item.role}</td>
                                                    <td>{renderStatus(item.status)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default EditAccount;

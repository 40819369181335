import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../src/components/Images/Logo.svg";

function Signup() {
    const navigate = useNavigate();
    const [input, setInput] = useState({
        email: "",
        username: "",
        number: "",
        password: "",
        confirm_password: "",
    });

    const [error, setError] = useState({
        emailerr: "",
        usernameerr: "",
        passworderr: "",
        numbererr: "",
        confirm_passworderr: "",
    });

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validateInput = (e) => {
        const { name, value } = e.target;

        const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        };
        setError((prev) => {
            const stateObj = { ...prev, [`${name}err`]: "" };

            if (name === "username") {
                if (!value.trim()) {
                    stateObj.usernameerr = "Please Enter Fullname*";
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    stateObj.usernameerr = "Invalid Fullname (letters only)*";
                }
            }

            if (name === "email") {
                if (!value.trim()) {
                    stateObj.emailerr = "Please Enter Email ID*";
                } else if (!validateEmail(value)) {
                    stateObj.emailerr = "Invalid Email ID*";
                }
            }

            if (name === "password") {
                if (!value.trim()) {
                    stateObj.passworderr = "Please Enter Password*";
                } else if (input?.confirm_password && value !== input?.confirm_password) {
                    stateObj.confirm_passworderr =
                        "Password and Confirm Password do not match*";
                }
            }

            if (name === "confirm_password") {
                if (!value.trim()) {
                    stateObj.confirm_passworderr = "Please Enter Confirm Password*";
                } else if (input?.password && value !== input?.password) {
                    stateObj.confirm_passworderr =
                        "Password and Confirm Password do not match*";
                }
            }

            return stateObj;
        });
    };

    const handleSubmit = () => {
        const { email, username, password, confirm_password } = input;
        let hasError = false;

        // Validate all fields before submission
        const updatedErrors = { ...error };

        if (!username.trim()) {
            updatedErrors.usernameerr = "Please Enter Fullname*";
            hasError = true;
        }

        if (!email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            updatedErrors.emailerr = email.trim()
                ? "Invalid Email ID*"
                : "Please Enter Email ID*";
            hasError = true;
        }

        if (!password.trim()) {
            updatedErrors.passworderr = "Please Enter Password*";
            hasError = true;
        } else if (password !== confirm_password) {
            updatedErrors.confirm_passworderr =
                "Password and Confirm Password do not match*";
            hasError = true;
        }

        setError(updatedErrors);

        if (!hasError) {
            // Make sure to send the data in JSON format
            axios
                .post(
                    `https://api.avessecurity.com/api/users/Signup`,
                    {
                        username: username,
                        email: email,
                        password: password,
                        confirm_password: confirm_password,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',  // Set Content-Type header to JSON
                        },
                    }
                )
                .then(() => {
                    navigate("/");  // Redirect to login page on successful signup
                })
                .catch((err) => {
                    // Log the error for debugging purposes
                    console.error("Error during signup:", err);
                    if (err.response) {
                        console.log("Error Response:", err.response.data);
                        // You can also set a more specific error message from the server
                        setError((prev) => ({
                            ...prev,
                            emailerr: err.response.data.message || prev.emailerr,
                        }));
                    }
                });
        }
    };

    return (
        <div>
            <form>
                <section className="signup mt-4">
                    <div className="container">
                        <div className="justify-content-center text-center">
                            <img src={logo} className="w-100 logo" />
                            <div className="box-signup">
                                <h5 className="text-center mt-3">Sign Up</h5>

                                {/* Full Name */}
                                <div>
                                    <input
                                        className="mt-4 w-50 p-2 form-control-lg-lg"
                                        type="text"
                                        name="username"
                                        placeholder="Enter Fullname*"
                                        value={input?.username}
                                        onChange={onInputChange}
                                        onBlur={validateInput}
                                    />
                                    <div>
                                        {error?.usernameerr && (
                                            <span className="signuperror">
                                                {error?.usernameerr}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* Email */}
                                <div>
                                    <input
                                        className="mt-4 w-50 p-2 form-control-lg-lg"
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email ID*"
                                        value={input?.email}
                                        onChange={onInputChange}
                                        onBlur={validateInput}
                                    />
                                    <div>
                                        {error?.emailerr && (
                                            <span className="signuperror">{error?.emailerr}</span>
                                        )}
                                    </div>
                                </div>

                                {/* Password */}
                                <div>
                                    <input
                                        className="mt-4 w-50 p-2 form-control-lg-lg"
                                        type="password"
                                        name="password"
                                        placeholder="Enter Password*"
                                        value={input?.password}
                                        onChange={onInputChange}
                                        onBlur={validateInput}
                                    />
                                    <div>
                                        {error?.passworderr && (
                                            <span className="signuperror">{error?.passworderr}</span>
                                        )}
                                    </div>
                                </div>

                                {/* Confirm Password */}
                                <div>
                                    <input
                                        className="mt-4 w-50 p-2 form-control-lg-lg"
                                        type="password"
                                        name="confirm_password"
                                        placeholder="Enter Confirm Password*"
                                        value={input?.confirm_password}
                                        onChange={onInputChange}
                                        onBlur={validateInput}
                                    />
                                    <div>
                                        {error?.confirm_passworderr && (
                                            <span className="signuperror">
                                                {error?.confirm_passworderr}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-4 w-25 button"
                                    onClick={handleSubmit}
                                >
                                    Sign Up
                                </button>

                                {/* Redirect to Login */}
                                <h6 className="accountsign mb-5 mt-4">
                                    Already have an account?
                                    <Link to="/" className="text-decoration-none">
                                        <span>Sign in</span>
                                    </Link>
                                </h6>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    );
}

export default Signup;

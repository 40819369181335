import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { FaEye, FaSearch } from "react-icons/fa";

function Activities(props) {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 2, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 3, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 4, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
    ]);

    const fetchDatanextprestaffall = () => {
        axios
            .get(`your_url/admin/Managpermission/index?page=$`)
            .then((res) => {
                // setDatastaffall(videosData);                 
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchDatanextprestaffall();
    }, []);

    const handleViewFile = (id) => {
        // Logic for viewing the file, e.g., navigating to a page or opening a modal
        window.location.href = `/view-details/${id}`;
    };

    return (
        <>
            <div className="m-0 p-0">
                <div className="container-fluid">
                    <section className="section mt-4">
                        <div className="card">
                            <div className="col-lg-12 row py-3">
                                <div className="col-6 col-sm-6 col-lg-6">
                                    <h3>FORM SUBMITED</h3>
                                </div>
                                <div className="col-6 col-sm-6 col-lg-6 text-end">
                                    <form className="d-flex">
                                        <div className="position-relative w-100">

                                            <FaSearch
                                                className="position-absolute text-muted"
                                                style={{
                                                    top: "50%",
                                                    left: "9px",
                                                    transform: "translateY(-50%)",
                                                    pointerEvents: "none", // Makes the icon unclickable
                                                }}
                                            />
                                            <input
                                                className="form-control bg-light ps-4"
                                                type="text"
                                                placeholder="Search here..."
                                               
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="col-lg-12 ">
                                <div className="card">
                                    <div className="p-2 text-start iq-card-body">
                                        <Table>
                                            <thead className="p-1">
                                                <tr className="title">
                                                    <th>#</th>
                                                    <th>NAMES</th>
                                                    <th>STAFF ID</th>
                                                    <th>DEPARTMENT</th>
                                                    <th>DESTIGNATION</th>
                                                    <th>ROLES</th>
                                                    <th>VIEW</th>
                                                </tr>
                                            </thead>
                                            {datastaffall?.map((item) => (
                                                <tbody key={item?.id}>
                                                    <tr>
                                                        <td>{item?.id}</td>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.staff_id}</td>
                                                        <td>{item?.department}</td>
                                                        <td>{item?.destignation}</td>
                                                        <td>{item?.role}</td>
                                                        <td>
                                                            <FaEye
                                                                onClick={() => handleViewFile(item?.id)}
                                                                title="View File"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </>
    );
}

export default Activities;

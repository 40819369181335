import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form } from "react-bootstrap";

function Modules() {
    const [modules, setModules] = useState([]); // To store modules fetched from the API
    const [selectedModule, setSelectedModule] = useState(null); // To track selected module for assigning
    const [roleName, setRoleName] = useState(""); // To store role name input
    const [newRoleName, setNewRoleName] = useState(""); // To store new role name input for updating
    const [showAssignModal, setShowAssignModal] = useState(false); // Assign modal visibility state
    const [showUpdateModal, setShowUpdateModal] = useState(false); // Update modal visibility state
    const [roles, setRoles] = useState([]); // To store roles fetched from API

    // Fetch modules from the API
    const fetchModulesAndRoles = async () => {
        try {
            const modulesResponse = await axios.get(`https://api.avessecurity.com/api/role/getAllmodule`);
            setModules(modulesResponse.data.modules || []);

            const rolesResponse = await axios.get(`https://api.avessecurity.com/api/Roles/getRole`);
            setRoles(rolesResponse.data.Roles || []);
            console.log("roles", rolesResponse.data.Roles)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // Assign selected module to a role
    const assignModuleToRole = async () => {
        if (!selectedModule || !roleName) {
            alert("Please select a module and enter a role name!");
            return;
        }
        try {
            await axios.post(`https://api.avessecurity.com/api/role/addRole`, {
                roleName,
                module: selectedModule,
            });
            alert("Module assigned successfully!");
            setShowAssignModal(false); // Close modal after successful assignment
            setRoleName(""); // Reset role name input
        } catch (error) {
            // Log the full error object to get more details
            console.error("Error assigning module to role:", error);
            if (error.response.data.message) {
                console.error("Response Error:", error.response);
                alert(`${error.response.data.message}`);
            } else if (error.request) {
                console.error("Request Error:", error.request);
                alert("Network error occurred. Please check your connection.");
            } else {
                console.error("General Error:", error.message);
                alert("An unexpected error occurred.");
            }
        }
    };

    // Update role with a new role name and module
    const updateRole = async () => {
        if (!roleName || !newRoleName || !selectedModule) {
            alert("Please fill in all fields!");
            return;
        }
        try {
            await axios.put(`https://api.avessecurity.com/api/role/updateRole`, {
                roleName,
                newRoleName,
                module: selectedModule,
            });
            alert("Role updated successfully!");
            setShowUpdateModal(false); // Close modal after successful update
            setRoleName(""); // Reset inputs
            setNewRoleName("");
        } catch (error) {
            console.error("Error updating role:", error);
            alert("Failed to update role. Please try again.");
        }
    };

    // Fetch modules when the component loads
    useEffect(() => {
        fetchModulesAndRoles();
    }, []);

    return (
        <>
            <div className="m-0 p-0">
                <div className="container-fluid">
                    <section className="section mt-4">
                        <div className="card">
                            <div className="col-lg-12 row py-3">
                                <div className="col-6">
                                    <h3>Modules</h3>
                                </div>
                                <div className="col-6 text-end">
                                    <Button variant="primary" onClick={() => setShowAssignModal(true)}>
                                        Assign Module to Role
                                    </Button>
                                    <Button variant="warning" className="ms-2" onClick={() => setShowUpdateModal(true)}>
                                        Update Role
                                    </Button>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="p-2 text-start iq-card-body">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Module Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {modules.map((module, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{module}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* Modal for assigning module to a role */}
            <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Module to Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Module</Form.Label>
                            <Form.Select
                                value={selectedModule}
                                onChange={(e) => setSelectedModule(e.target.value)}
                            >
                                <option value="">-- Select a Module --</option>
                                {modules.map((module, index) => (
                                    <option key={index} value={module}>
                                        {module}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                            >
                                <option value="">-- Select a Role --</option>
                                {roles.map((role) => (
                                    <option key={role._id} value={role.name}>
                                        {role.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAssignModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={assignModuleToRole}>
                        Assign Module
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for updating role */}
            <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group className="mb-3">
                            <Form.Label>Select Module</Form.Label>
                            <Form.Select
                                value={selectedModule}
                                onChange={(e) => setSelectedModule(e.target.value)}
                            >
                                <option value="">-- Select a Module --</option>
                                {modules.map((module, index) => (
                                    <option key={index} value={module}>
                                        {module}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Current Role Name</Form.Label>
                            <Form.Control
                                type="text"
                                // placeholder="Enter current role name (e.g., Manager)"
                                value={roleName}
                                onChange={(e) => setRoleName(e.target.value)}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Role</Form.Label>
                            <Form.Select
                                value={newRoleName}
                                onChange={(e) => setNewRoleName(e.target.value)}
                            >
                                <option value="">-- Select a Role --</option>
                                {roles.map((role) => (
                                    <option key={role._id} value={role.name}>
                                        {role.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
                        Close
                    </Button>
                    <Button variant="warning" onClick={updateRole}>
                        Update Role
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Modules;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../src/components/Images/Logo.svg";

const Login = ({ onLogin }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [inputField, setInputField] = useState({
        emailOrUsername: "",  // Combined email and username input
        password: "",
    });
    const [errField, setErrField] = useState({
        emailOrUsernameErr: "",
        passwordErr: "",
    });

    function handleChange(e) {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (localStorage.getItem("access_token_content") !== null) {
            navigate("/dashboard");
        }
    }, [navigate]);

    const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    async function login() {
        try {
            const { emailOrUsername, password } = inputField;

            // Determine if the input is an email or username
            const payload = {
                [emailOrUsername.includes("@") ? "email" : "username"]: emailOrUsername,
                password,
            };

            const response = await axios.post(
                `https://api.avessecurity.com/api/users/Signin`,
                payload,
                { headers }
            );

            if (response.data.success) {
                const access_token = response.data.token;
                const username = response.data.username;

                localStorage.setItem("access_token", access_token);
                localStorage.setItem("username", username);

                // Pass the access token back to the parent component
                onLogin(access_token);

                navigate("/dashboard");
            } else {
                alert('Login failed. Please check your details.');
            }
        } catch (error) {
            // alert('Please Enter Correct Details');
            alert(error.response.data.message); // Display the error message returned by the server
            console.error(error);
        }
    }

    const validForm = (e) => {
        e.preventDefault();

        const validateEmail = (email) => {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };

        let formIsValid = true;
        setErrField({
            emailOrUsernameErr: "",
            passwordErr: "",
        });

        if (inputField?.emailOrUsername === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                emailOrUsernameErr: "Please Enter Email or Username*",
            }));
        } else if (!validateEmail(inputField?.emailOrUsername) && inputField?.emailOrUsername !== "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                emailOrUsernameErr: "Please Enter a Valid Email*",
            }));
        }

        if (inputField?.password === "") {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                passwordErr: "Please Enter Password*",
            }));
        }

        if (formIsValid) {
            login();
        }
    };

    return (
        <div>
            {isLoading ? (
                <div>Loading...</div>
            ) : (

                <div className="d-flex flex-row " style={{ height: "100vh" }}>
                    <div className="d-flex justify-content-center align-items-center w-50" style={{ backgroundColor: "black" }}>
                        <img src="https://s3-alpha-sig.figma.com/img/58d6/d574/058248ce065d422fdcb95f67b11f5401?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=PGsWC8CC2Ll9hU63WitRIx7bGmkmWMIXkBboZlZI6rRtTbLhTmQ8MA2Qj5hIRgOdm7bwqzaQZSD~dE3V38Ifa2foV~tCJjBJx8kqQdKelb62GBkgnzb01y1c1XGo3Q7jhzti4YHgBAPc7z~6rqtqCjVJM2hqy2No1utciyE1fk-K2yiZfdCeDGR98ufbURgO-STLDx7HbTvCV3Uc~0SNcyl3Hl5uCrZ-~d4GETYVeedfQdaCoag-WLaN-HOr7ZwBDC5ibK-xEcM8f-7~R4l7XMC2b~Dqm8Yae-1STpSxjaTErnaaxvTvVf7AtWkWu~TX-kmtKSv5vZ6RC-mALeb85g__"
                            alt="AVES" className="w-50"></img>
                    </div>
                    <form onSubmit={validForm} className="w-100 d-flex align-items-center">
                        <section className="signin mt-3 w-100">
                            <div className="container">
                                <div className="justify-content-center text-center">
                                    {/* <img src={logo} className="w-100 logo" /> */}
                                    <div>

                                    </div>
                                    <div className="box-signin mt-4">
                                        <h2 className="text-center mt-4">Login</h2>
                                        <p className="mt-3">Login to access your Aves admin  account</p>
                                        <div>
                                            <input
                                                id="emailOrUsername"
                                                name="emailOrUsername"
                                                className="mt-4 w-50 p-2 form-control-lg-lg"
                                                value={inputField?.emailOrUsername}
                                                onChange={handleChange}
                                                placeholder="Enter Email or Username"
                                                type="text"
                                                autoComplete="off"
                                            />
                                            <div>
                                                {errField?.emailOrUsernameErr && (
                                                    <span className="siginerror">{errField?.emailOrUsernameErr}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <input
                                                id="password"
                                                name="password"
                                                value={inputField?.password}
                                                onChange={handleChange}
                                                className="mt-4 w-50 p-2"
                                                placeholder="Enter Your Password"
                                                autoComplete="off"
                                            />
                                            <div>
                                                {errField?.passwordErr && (
                                                    <span className="siginerror">{errField?.passwordErr}</span>
                                                )}
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-4 w-25 button bg-dark"
                                        >
                                            Sign In
                                        </button>

                                        <h6 className="accountsign mb-5 mt-4">
                                            Don't have an account..  {"  "}
                                            <Link to="/signup" className="text-decoration-none">
                                                <span>Sign Up Here?</span>
                                            </Link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>

            )}
        </div>
    );
};

export default Login;

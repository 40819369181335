import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import edititem from "../../src/components/Images/edit.svg";
import { FaSearch } from 'react-icons/fa';
import { RiDeleteBin6Line } from "react-icons/ri";


const ManageTeams = () => {
    const [datastaffall, setDatastaffall] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const fetchStaffData = () => {
        axios
            .get(`https://api.avessecurity.com/api/users/userdata`) // API endpoint
            .then((res) => {
                setDatastaffall(res.data.User); // Assuming the staff data is in the "User" key
                console.log(res.data.User)
            })
            .catch((err) => {
                console.log("Error fetching data:", err);
            });
    };
    useEffect(() => {
        fetchStaffData();
    }, []);

    const filterData = () => {
        return datastaffall.filter((item) => {
            return (
                item.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.StaffIdNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.Department.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.Designation.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.role.toLowerCase().includes(searchQuery.toLowerCase())
            );
        });
    };
    return (
        <>

            <div className=" m-0 p-0">
                <div className="container-fluid">
                    <section className="section mt-4">
                        <div className="card">
                            <div className="col-lg-12 row py-3">
                                <div className="col-5 col-sm-5 col-lg-5">
                                    <h3> Teams </h3>
                                </div>

                                <div className="col-4 col-sm-4 col-lg-4">
                                    <form className="d-flex">
                                        <div className="position-relative w-100">

                                            <FaSearch
                                                className="position-absolute text-muted"
                                                style={{
                                                    top: "50%",
                                                    left: "9px",
                                                    transform: "translateY(-50%)",
                                                    pointerEvents: "none", // Makes the icon unclickable
                                                }}
                                            />
                                            <input
                                                className="form-control bg-light ps-4"
                                                type="text"
                                                placeholder="Search here..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-3 col-sm-3 col-lg-3 text-end">
                                    <button
                                        type="button"
                                        className="btn  bg-light"
                                        onClick={() => window.location.href = "/create-team"}
                                    >
                                        Create Team
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-12 ">
                                <div className="card">

                                    <div className="p-2 text-start iq-card-body">
                                        <Table>
                                            <thead className="p-1">
                                                <tr className="title">

                                                    <th>#</th>
                                                    <th>TEAM NAME </th>
                                                    {/* <th>STAFF ID</th> */}
                                                    {/* <th>DEPARTMENT</th> */}
                                                    {/* <th>DESTIGNATION</th> */}
                                                    {/* <th>ROLES</th> */}
                                                    <th>VIEW</th>
                                                </tr>
                                            </thead>
                                            {filterData().length === 0 ? ( // Check if no data after filtering
                                                <tr>
                                                    <td colSpan="7" className="text-center">No Data Found</td>  {/* Display the message if no data */}
                                                </tr>) : filterData()?.map((item, key) => (
                                                    <tbody>
                                                        <tr key={item?.id}>
                                                            <td>
                                                                <div>{item?._id}</div>
                                                            </td>

                                                            <td> {item?.username}</td>
                                                            {/* <td> {item?.StaffIdNumber}</td> */}
                                                            {/* <td> {item?.Department}</td> */}
                                                            {/* <td> {item?.Designation}</td> */}
                                                            {/* <td> {item?.role?.name}</td> */}

                                                            <td>
                                                                <div className="editdropdown">
                                                                    <div className="editdropdown-staff">
                                                                        <button
                                                                            type="button"
                                                                            className="edit border-0"
                                                                        >
                                                                            <Link
                                                                                to={`/edit-staff/${item?._id}`}
                                                                                state={{ userData: item }}
                                                                            >

                                                                                <span>
                                                                                    <img
                                                                                        src={edititem}
                                                                                        alt="flogo"
                                                                                        width={20}
                                                                                        height={20}
                                                                                    />

                                                                                </span>
                                                                            </Link>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="edit border-0 ms-2 fs-5"
                                                                        >
                                                                            <Link
                                                                                to={`/edit-staff/${item?._id}`}
                                                                                state={{ userData: item }}
                                                                                className='text-dark'
                                                                            >

                                                                                <span>
                                                                                    <RiDeleteBin6Line className='text-dark' />


                                                                                </span>
                                                                            </Link>
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                        </Table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>


        </>
    )
}

export default ManageTeams

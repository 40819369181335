import React, { useState, useEffect } from "react";
import edititem from "../../src/components/Images/edit.svg";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function ManageRoles() {
    const [datastaffall, setDatastaffall] = useState([]);
    // const [datastaffall, setDatastaffall] = useState([
    //     { id: 1, name: "Guest", },
    //     { id: 2, name: "Superviour", },
    //     { id: 3, name: "Manager", },
    //     { id: 4, name: "Admin", },]);



    const fetchRoles = () => {
        axios
            .get(
                `https://api.avessecurity.com/api/Roles/getRole`,

            )
            .then((res) => {
                console.log("roles", res.data.Roles)
                setDatastaffall(res.data.Roles);
            })
            .catch((err) => {
                console.log(err);
            });
    };



    useEffect(() => {
        fetchRoles();
    }, []);


    return (
        <>

            <div className=" m-0 p-0">
                <div className="container-fluid">
                    <section className="section mt-4">
                        <div className="card">
                            <div className="col-lg-12 row py-3">
                                <div className="col-6 col-sm-6 col-lg-6">
                                    <h3> MANAGE ROLES </h3>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 text-end">
                                    {/* <button
                                        type="button"
                                        className="btn  bg-light"
                                        onClick={() => window.location.href = "/add-role"}
                                    >
                                        ADD ROLE
                                    </button> */}
                                    <Link to="/add-role" className="btn bg-light">
                                        ADD ROLE
                                    </Link>
                                </div>

                            </div>

                            <div className="col-lg-12 ">
                                <div className="card">

                                    <div className="p-2 text-start iq-card-body">
                                        <Table>
                                            <thead className="p-1">
                                                <tr className="title">

                                                    <th>USER ID</th>
                                                    <th>ROLES </th>
                                                    <th>ACTION</th>
                                                </tr>
                                            </thead>
                                            {datastaffall?.map((item, key) => (
                                                <tbody>
                                                    <tr key={item?._id}>
                                                        <td>
                                                            <div>{key + 1}</div>
                                                        </td>

                                                        <td> {item?.name}</td>

                                                        <td>
                                                            <div className="editdropdown">
                                                                <div className="editdropdown-staff">

                                                                    <button
                                                                        type="button"
                                                                        className="edit border-0"
                                                                    // onClick={() => window.location.href = "/edit-roles/" + item?._id}
                                                                    >
                                                                        <Link
                                                                            to={`/edit-roles/${item._id}`}
                                                                            state={{ userData: item }}
                                                                        >
                                                                            <span>
                                                                                <img
                                                                                    src={edititem}
                                                                                    alt="flogo"
                                                                                    width={20}
                                                                                    height={20}
                                                                                />

                                                                            </span>
                                                                        </Link>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </Table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default ManageRoles;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import { Spinner } from "react-bootstrap"
function Calendarreportpage() {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 2, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 3, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
        { id: 4, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
    ]);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [status, setStatus] = useState('');
    const [department, setDepartment] = useState('');
    const [noDataMessage, setNoDataMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // Fetch modules from API
    useEffect(() => {
        axios.get(`https://api.avessecurity.com/api/collection/getModule`)
            .then(response => {
                if (Array.isArray(response.data)) {
                    const formattedModules = response.data.map(module => ({
                        value: module,
                        label: module.replace(/([a-z])([A-Z])/g, '$1 $2') // Format name
                    }));
                    setModules(formattedModules);
                    // console.log("formated module : ", formattedModules)
                }
            })
            .catch(error => {
                console.error("Error fetching modules:", error);
            });
    }, []);

    const validateDates = () => {
        if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
            alert("End date cannot be before start date.");
            setEndDate(''); // Clear the end date value
            return false;
        }
        return true;
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        validateDates();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateDates()) {
            return;
        }

        // Check if the selected module is "emdoorchecklistsnews"
        if (!selectedModule) {
            alert("Please select any module to generate the report.");
            return;
        }

        const requestData = {
            startDate,
            endDate,
            name,
            location,
            status,
        };
        console.log("requst data : ", requestData)
        setLoading(true);
        console.log("selected modeile", selectedModule)
        try {
            const response = await axios.post(
                `https://api.avessecurity.com/api/ReportGenrate/Pdf/${selectedModule?.value}`,
                requestData,
                {
                    responseType: "blob", // Important for handling file downloads
                }
            );

            // Create a blob URL for downloading
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const fileName = `${selectedModule.label}_Report.pdf`;
            link.setAttribute("download", fileName); // Change filename if needed
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error generating report:", error);
            alert("Failed to generate report. Please try again.");
        }
        finally {
            setLoading(false); // Stop loading spinner
        }
    };

    return (

        <div className="m-0 p-0">
            {loading && (
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100vh", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}>
                    <Spinner animation="border" role="status" variant="light">
                    </Spinner>
                    <span className="text-white">Generating Report...</span>
                </div>
            )}
            <div className="container-fluid">
                <section className="section mt-4">
                    <div className="card p-4">
                        <form onSubmit={handleSubmit} className="row g-3">
                            <div className="col-md-3">
                                <label className="form-label fw-bold">SELECT START DATE</label>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">SELECT END DATE</label>
                                <input type="date" className="form-control" value={endDate} onChange={handleEndDateChange} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">NAME</label>
                                <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">LOCATION</label>
                                <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">STATUS</label>
                                <input type="text" className="form-control" value={status} onChange={(e) => setStatus(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">DEPARTMENT</label>
                                <input type="text" className="form-control" value={department} onChange={(e) => setDepartment(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label fw-bold">MODULE</label>
                                <Select
                                    options={modules}
                                    value={selectedModule}
                                    onChange={setSelectedModule}
                                    placeholder="Search & select module"
                                    isSearchable
                                />
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <button type="submit" className="btn btn-dark" style={{ borderRadius: "25px" }}>

                                    Generate Report
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-12 row py-3">
                        <div className="col-6 col-sm-6 col-lg-6">
                            <h3>KEY MANAGEMENT</h3>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 text-end">
                            <h4>27/12/2024</h4>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="p-2 text-start iq-card-body">
                                <Table>
                                    <thead className="p-1">
                                        <tr className="title">
                                            <th>#</th>
                                            <th>NAMES</th>
                                            <th>STAFF ID</th>
                                            <th>DEPARTMENT</th>
                                            <th>DESTIGNATION</th>
                                            <th>ROLES</th>
                                            <th>VIEW</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datastaffall.length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">No records found for the selected filters</td>
                                            </tr>
                                        ) : (
                                            datastaffall.map((item) => (
                                                <tr key={item?.id}>
                                                    <td>{item?.id}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.staff_id}</td>
                                                    <td>{item?.department}</td>
                                                    <td>{item?.destignation}</td>
                                                    <td>{item?.role}</td>
                                                    <td>
                                                        <FaFileAlt
                                                            // onClick={() => handleViewFile(item?.id)}
                                                            title="View File"
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Calendarreportpage;
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   FaTh,
//   FaRegChartBar,
//   FaShoppingCart,
//   FaClock,
//   FaChartLine,
//   FaCog,
// } from "react-icons/fa";
// import logo from "../../src/components/Images/Logo.svg";

// const Sidebar = ({ children }) => {
//   const [isOpen, setIsOpen] = useState(true);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown

//   const toggle = () => setIsOpen(!isOpen);

//   const menuItem = [
//     { path: "/dashboard", name: "Dashboard", icon: <FaTh /> },
//     {
//       name: "Roles and Permission",
//       icon: <FaRegChartBar />,
//       isDropdown: true,
//       subPaths: [
//         { path: "/manage-staffs", name: "ManageStaff" },
//         { path: "/manage-role", name: "Manage Roles" },
//         { path: "/manage-permission", name: "Manage Permission" },
//       ],
//     },
//     { path: "/modules", name: "Modules", icon: <FaShoppingCart /> },
//     { path: "/activity", name: "Activity", icon: <FaClock /> },
//     {
//       path: "/calendarreport",
//       name: "Calendar Report Page",
//       icon: <FaChartLine />,
//     },
//     { path: "/settings", name: "Settings", icon: <FaCog /> },
//   ];

//   return (
//     <div className="container-lfs">
//       <div className={`sidebar ${isOpen ? "open" : "closed"} p-2`}>
//         <div className="top_sections">
//           <div className="text-center">
//             <Link to="/dashboard">
//               {isOpen && <img src={logo} alt="Logo" className="logo" />}
//             </Link>
//           </div>
//           <div className="toggle" onClick={toggle}></div>
//         </div>
//         {menuItem.map((item, index) => {
//           if (item.isDropdown) {
//             return (
//               <div key={index}>
//                 <div
//                   className="link"
//                   onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//                 >
//                   <div className="icon">{item.icon}</div>
//                   {isOpen && <div className="link_text">{item.name}</div>}
//                 </div>
//                 {isDropdownOpen &&
//                   item.subPaths.map((subItem, subIndex) => (
//                     <Link
//                       to={subItem.path}
//                       key={subIndex}
//                       className="link sub_link"
//                     >
//                       {isOpen && (
//                         <div className="link_text">{subItem.name}</div>
//                       )}
//                     </Link>
//                   ))}
//               </div>
//             );
//           }
//           return (
//             <Link to={item.path} key={index} className="link">
//               <div className="icon">{item.icon}</div>
//               {isOpen && <div className="link_text">{item.name}</div>}
//             </Link>
//           );
//         })}
//       </div>
//       <main className="content">{children}</main>
//     </div>
//   );
// };

// export default Sidebar;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdGroups } from "react-icons/md"
import {
  FaTh,
  FaRegChartBar,
  FaShoppingCart,
  FaClock,
  FaChartLine,
  FaCog,
} from "react-icons/fa";
import logo from "./Images/Logo.svg";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();
  const toggleSidebar = () => setIsOpen(!isOpen);

  return (
    <div className="container-lfs">
      <div className={`sidebar ${isOpen ? "open" : "closed"} p-2`}>
        <div className="top_sections">
          <div className="text-center">
            <Link to="/dashboard">
              {isOpen && <img src={logo} alt="Logo" className="logo" />}
            </Link>
          </div>
          <div className="toggle" onClick={toggleSidebar}></div>
        </div>

        {/* Directly defining menu items */}
        <Link
          to="/dashboard"
          className={`link ${location.pathname === "/dashboard" ? "active" : ""
            }`}
        >
          <div className="icon">
            <FaTh />
          </div>
          {isOpen && <div className="link_text">Dashboard</div>}
        </Link>

        <div
          className={`link ${dropdownOpen ? "active" : ""}`}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <div className="icon">
            <FaRegChartBar />
          </div>
          {isOpen && <div className="link_text">Roles and Permissions</div>}
        </div>
        {dropdownOpen && (
          <>
            <Link
              to="/manage-staffs"
              className={`link sub_link ${location.pathname === "/manage-staffs" ? "active" : ""
                }`}
            >
              {isOpen && <div className="link_text">Manage Staffs</div>}
            </Link>
            <Link
              to="/manage-role"
              className={`link sub_link ${location.pathname === "/manage-role" ? "active" : ""
                }`}
            >
              {isOpen && <div className="link_text">Manage Roles</div>}
            </Link>
            <Link
              to="/Manage-Permissions"
              className={`link sub_link ${location.pathname === "/Manage-Permissions" ? "active" : ""
                }`}
            >
              {isOpen && <div className="link_text">Manage Permission</div>}
            </Link>
            {/* <Link
              to="/manage-permission"
              className={`link sub_link ${location.pathname === "/manage-permission" ? "active" : ""
                }`}
            >
              {isOpen && <div className="link_text">Manage Permissions</div>}
            </Link> */}
          </>
        )}

        <Link
          to="/modules"
          className={`link ${location.pathname === "/modules" ? "active" : ""}`}
        >
          <div className="icon">
            <FaShoppingCart />
          </div>
          {isOpen && <div className="link_text">Modules</div>}
        </Link>

        <Link
          to="/activity"
          className={`link ${location.pathname === "/activity" ? "active" : ""
            }`}
        >
          <div className="icon">
            <FaClock />
          </div>
          {isOpen && <div className="link_text">Activity</div>}
        </Link>

        <Link
          to="/teams"
          className={`link ${location.pathname === "/teams" ? "active" : ""
            }`}
        >
          <div className="icon">
            <MdGroups />
          </div>
          {isOpen && <div className="link_text">Teams</div>}
        </Link>
        <Link
          to="/calendarreport"
          className={`link ${location.pathname === "/calendarreport" ? "active" : ""
            }`}
        >
          <div className="icon">
            <FaChartLine />
          </div>
          {isOpen && <div className="link_text">Report Generate Page</div>}
        </Link>

        <Link
          to="/settings"
          className={`link ${location.pathname === "/settings" ? "active" : ""
            }`}
        >
          <div className="icon">
            <FaCog />
          </div>
          {isOpen && <div className="link_text">Settings</div>}
        </Link>
      </div>

      <main className="content">{children}</main>
    </div>
  );
};

export default Sidebar;

// import React, { useState, } from "react";

// function AddRole() {
//     return (
//         <>

//             <div className=" m-0 p-0">
//                 <div className="container-fluid">
//                     <section className="section">
//                         <div className="card mt-4">

//                             <div className="col-lg-12 row py-3">
//                                 <div className="col-6 col-sm-6 col-lg-6">
//                                     <h3>CREATE ROLE</h3>
//                                 </div>
//                             </div>
//                             <hr />
//                             <div>
//                                 <div className="p-2 text-start iq-card-body">
//                                     <label className="fw-bold mt-1">ROLE NAME</label>
//                                 </div>
//                                 <div className="p-2 text-start iq-card-body d-flex">
//                                     <input className="form-control-lg-lg" type="text" />
//                                     <div>
//                                         <button className="bg-dark text-white rounded-pill p-2 ms-5">Submit</button>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                     </section>
//                 </div>
//             </div>


//         </>
//     );
// }

// export default AddRole;
import React, { useState } from "react";
import axios from "axios";

function AddRole() {
    const [roleName, setRoleName] = useState("");
    const [checked, setChecked] = useState({
        view: false,
        edit: false,
        create: false,
        delete: false,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCheckboxChange = (permission) => {
        setChecked((prevState) => ({
            ...prevState,
            [permission]: !prevState[permission],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!roleName.trim()) {
            setError("Role Name cannot be empty!");
            setLoading(false);
            return;
        }

        const roleData = {
            name: roleName,
            permissions: checked,
        };

        console.log("Submitting Role Data:", roleData);

        try {
            const response = await axios.post("http://api.avessecurity.com/api/Roles/createRole", roleData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            alert("Role created successfully!");
            console.log("Role created successfully:", response.data);
        } catch (err) {
            if (err.response && err.response.status === 400 && err.response.data.message === "Role name already taken") {
                setError("Role name already taken");
            } else {
                setError(err.message || "An error occurred while creating the role");
            }
            console.error("Error creating role:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="m-0 p-0">
            <div className="container-fluid">
                <section className="section">
                    <div className="card mt-4">
                        <div className="col-lg-12 row py-3">
                            <div className="col-6 col-sm-6 col-lg-6">
                                <h3>CREATE ROLE</h3>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div className="p-2 text-start iq-card-body">
                                <label className="fw-bold mt-1">ROLE NAME</label>
                            </div>
                            <div className="p-2 text-start iq-card-body d-flex">
                                <input
                                    className="form-control-lg-lg"
                                    type="text"
                                    value={roleName}
                                    onChange={(e) => setRoleName(e.target.value)}
                                />
                            </div>

                            <div className="button-wrapper mt-3 d-flex gap-2">
                                {Object.keys(checked).map((permission) => (
                                    <button
                                        key={permission}
                                        className={`styled-button ${checked[permission] ? "checked" : ""}`}
                                        onClick={() => handleCheckboxChange(permission)}
                                    >
                                        <span>{permission.toUpperCase()}</span>
                                        <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={checked[permission]}
                                            onChange={() => handleCheckboxChange(permission)}
                                            style={{ display: "none" }}
                                        />
                                    </button>
                                ))}
                            </div>

                            <div className="mt-4">
                                <button
                                    className="bg-dark text-white rounded-pill p-2"
                                    onClick={handleSubmit}
                                    disabled={loading}
                                >
                                    {loading ? "Submitting..." : "Submit"}
                                </button>
                                {error && <p className="text-danger mt-2">{error}</p>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default AddRole;

import React, { useState, useEffect } from "react";
import axios from "axios";
import profile_image from "../../src/components/Images/profile_image.svg"; // Placeholder image

function AddStaff() {
    const [formData, setFormData] = useState({
        UserImage: null,
        FirstName: "",
        LastName: "",
        username: "",
        Gender: "",
        Department: "",
        Designation: "",
        // Occupation: "",
        Race: "",
        PassportNumber: "",
        StaffIdNumber: "",
        CompanyName: "",
        EmailId: "",
        ContactNumber: "",
        NRICNumber: "",
        role: "", // Initially empty
        Address: "",
        password: "",
        confirmPassword: "",
        Location: ""
    });

    const [roles, setRoles] = useState([]); // State to store fetched roles
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Fetch roles from the API
        axios.get("https://api.avessecurity.com/api/Roles/getRole")
            .then(response => {
                if (response.data?.Roles) {
                    setRoles(response.data.Roles); // Store roles in state
                }
            })
            .catch(error => console.error("Error fetching roles:", error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the field is role, find the corresponding _id
        if (name === "role") {
            const selectedRole = roles.find(role => role._id === value);
            setFormData({ ...formData, role: selectedRole ? selectedRole._id : "" });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    const handleImageChange = (e) => {
        setFormData({ ...formData, UserImage: e.target.files[0] });
    };

    const validateForm = () => {
        let newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== "UserImage") {
                newErrors[key] = `${key} is required`;
            }
        });
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });
        console.log(formDataToSend);
        try {
            const response = await axios.post(`https://api.avessecurity.com/api/users/register`, formDataToSend, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            console.log("Success:", response.data);
            alert("Account Created Successfully!");
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            if (error.response?.data?.message === "Username already taken") {
                setErrors((prevErrors) => ({ ...prevErrors, username: "Username already taken" }));
            }
        }
    };

    return (
        <div className="container-fluid">
            <section className="section">
                <div className="card mt-4 p-4">
                    <h3>ACCOUNT CREATE</h3>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 text-center">
                            <input
                                type="file"
                                name="UserImage"
                                id="upload-image"
                                onChange={handleImageChange}
                                className="form-control"
                                style={{ display: "none" }}
                            />
                            <label
                                htmlFor="upload-image"
                                className="d-block text-center"
                                style={{
                                    cursor: "pointer",
                                    backgroundImage: `url(${formData.UserImage ? URL.createObjectURL(formData.UserImage) : profile_image})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    width: "150px",
                                    height: "150px",
                                    borderRadius: "50%",
                                    margin: "0 auto",
                                    border: "2px solid #ddd"
                                }}
                            />
                        </div>

                        <div className="row justify-content-center">
                            {/* Left column */}
                            <div className="col-md-6">
                                {["FirstName", "LastName", "username", "Gender", "Department", "Designation", "Race", "password"].map((key) => (
                                    <div key={key} className="mb-3">
                                        <label className="fw-bold">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                                        <input
                                            type="text"
                                            name={key}
                                            value={formData[key]}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                        {errors[key] && <small className="text-danger">{errors[key]}</small>}
                                    </div>
                                ))}
                            </div>

                            {/* Right column */}
                            <div className="col-md-6">
                                {["StaffIdNumber", "CompanyName", "EmailId", "ContactNumber", "NRICNumber", "PassportNumber", "Address", "confirmPassword", "Location"].map((key) => (
                                    <div key={key} className="mb-3">
                                        <label className="fw-bold">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</label>
                                        <input
                                            type={key.includes("password" || "confirmPassword") ? "password" : "text"}
                                            name={key}
                                            value={formData[key]}
                                            onChange={handleChange}
                                            className="form-control"
                                        />
                                        {errors[key] && <small className="text-danger">{errors[key]}</small>}
                                    </div>
                                ))}

                                {/* Role Dropdown */}
                                <div className="mb-3">
                                    <label className="fw-bold">Role</label>
                                    <select
                                        name="role"
                                        value={formData.role}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="">Select a Role</option>
                                        {roles.map(role => (
                                            <option key={role._id} value={role._id}>{role.name}</option> // Use `_id` as value
                                        ))}
                                    </select>
                                    {errors.role && <small className="text-danger">{errors.role}</small>}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center w-100">
                            <button type="submit" className="btn btn-dark w-50">Create Account</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default AddStaff;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { FaSearch, FaEye } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Line } from "react-chartjs-2";

function GatePass() {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
        { id: 2, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "offline" },
        { id: 3, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "deactivate" },
        { id: 4, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
    ]);





    const data = {
        labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        datasets: [
            {
                label: "Last Month",
                data: [10, 20, 15, 25, 20, 30, 40],
                borderColor: "#4A90E2",
                backgroundColor: "rgba(74, 144, 226, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#4A90E2",
            },
            {
                label: "This Month",
                data: [5, 15, 10, 20, 15, 25, 50],
                borderColor: "#00C48C",
                backgroundColor: "rgba(0, 196, 140, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#00C48C",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    };
    const handleViewFile = (id) => {
        // Logic for viewing the file, e.g., navigating to a page or opening a modal
        window.location.href = `/gate-details/${id}`;
    };
    const [dayWiseStats, setDayWiseStats] = useState([]);
    const [monthWiseStats, setMonthWiseStats] = useState([]);
    const [totalWeekEntries, setTotalWeekEntries] = useState(0);
    const [totalMonthEntries, setTotalMonthEntries] = useState(0);
    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async () => {
        try {
            const res = await axios.get("https://api.avessecurity.com/api/gatepassForm/GatePassFormData");
            setDayWiseStats(res.data.dayWiseStats);
            setMonthWiseStats(res.data.monthWiseStats);
            const weekTotal = res.data.dayWiseStats.reduce((sum, day) => sum + day.count, 0);
            const monthTotal = res.data.monthWiseStats.reduce((sum, month) => sum + month.count, 0);

            setTotalWeekEntries(weekTotal);
            setTotalMonthEntries(monthTotal);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const dayWiseData = {
        labels: dayWiseStats.map((d) => d.day),
        datasets: [
            {
                label: "Gate Pass Entries (Day-wise)",
                data: dayWiseStats.map((d) => d.count),
                borderColor: "#4A90E2",
                backgroundColor: "rgba(74, 144, 226, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#4A90E2",
            },
        ],
    };

    const monthWiseData = {
        labels: monthWiseStats.map((m) => m.month),
        datasets: [
            {
                label: "Gate Pass Entries (Month-wise)",
                data: monthWiseStats.map((m) => m.count),
                borderColor: "#00C48C",
                backgroundColor: "rgba(0, 196, 140, 0.2)",
                fill: true,
                tension: 0.4,
                pointBackgroundColor: "#00C48C",
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: true },
        },
        scales: {
            x: { display: true },
            y: { display: true },
        },
    };


    return (
        <div className="container-fluid">
            <section className="section mt-4">
                <div className="row g-4">
                    <div className="col-lg-6">
                        <div className="gatepassdashboard-card w-100 h-100">
                            <h5>GATE PASS ENTRIES BY DAY</h5>
                            <div className="gatepassdashboard-chart-container">
                                <Line data={dayWiseData} options={chartOptions} />
                            </div>
                            <div className="gatepassdashboard-legend">

                                <div className="gatepassdashboard-legend-item">
                                    <span className="gatepassdashboard-dot" style={{ backgroundColor: "#4A90E2" }}></span>
                                    This Week
                                    <span className="gatepassdashboard-value">{totalWeekEntries}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="gatepassdashboard-card w-100 h-100">
                            <h5>GATE PASS ENTRIES BY MONTH</h5>
                            <div className="gatepassdashboard-chart-container">
                                <Line data={monthWiseData} options={chartOptions} />
                            </div>
                            <div className="gatepassdashboard-legend">

                                <div className="gatepassdashboard-legend-item">
                                    <span className="gatepassdashboard-dot" style={{ backgroundColor: "#00C48C" }}></span>
                                    This Month
                                    <span className="gatepassdashboard-value">{totalMonthEntries}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="dashboard-section d-flex justify-content-center align-items-center flex-column">
                            <h5>Calendar</h5>
                            <Calendar className="custom-calendar" />
                        </div>
                    </div>
                    <div className="col-lg-6 dashboard-section">
                        <div>
                            <label htmlFor="startDate" className="form-label fw-bold mt-3">
                                SELECT START DATE
                            </label>
                            <input type="date" className="form-control" id="startDate" name="startDate" />
                        </div>
                        <div>
                            <label htmlFor="endDate" className="form-label fw-bold mt-3">
                                SELECT END DATE
                            </label>
                            <input type="date" className="form-control" id="endDate" name="endDate" />
                        </div>
                        <div className="text-center mt-5">
                            <button type="button" className="btn btn-dark rounded-pill">
                                SELECT
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="col-lg-12 row py-3">
                        <div className="col-6 col-sm-6 col-lg-6">
                            <h3>GATEPASS</h3>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 text-end">
                            <form className="d-flex justify-content-end">
                                <div className="position-relative w-75">
                                    <FaSearch
                                        className="position-absolute text-muted"
                                        style={{
                                            top: "50%",
                                            left: "9px",
                                            transform: "translateY(-50%)",
                                            pointerEvents: "none", // Makes the icon unclickable
                                        }}
                                    />
                                    <input
                                        className="form-control bg-light ps-4"
                                        type="text"
                                        placeholder="Search here..."
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-lg-12 ">
                        <div className="card">
                            <div className="p-2 text-start iq-card-body">
                                <Table>
                                    <thead className="p-1">
                                        <tr className="title">
                                            <th>#</th>
                                            <th>NAMES</th>
                                            <th>STAFF ID</th>
                                            <th>DEPARTMENT</th>
                                            <th>DESTIGNATION</th>
                                            <th>ROLES</th>
                                            <th>VIEW</th>
                                        </tr>
                                    </thead>
                                    {datastaffall?.map((item) => (
                                        <tbody key={item?.id}>
                                            <tr>
                                                <td>{item?.id}</td>
                                                <td>{item?.name}</td>
                                                <td>{item?.staff_id}</td>
                                                <td>{item?.department}</td>
                                                <td>{item?.destignation}</td>
                                                <td>{item?.role}</td>
                                                <td>
                                                    <FaEye
                                                        onClick={() => handleViewFile(item?.id)}
                                                        title="View File"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default GatePass;


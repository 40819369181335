// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Table } from "react-bootstrap";
// import { FaEye, FaSearch } from "react-icons/fa";
// import { Line, Bar } from 'react-chartjs-2';

// const lineChartData = {
//     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//     datasets: [
//         {
//             label: 'Guard',
//             data: [0, 180, 240, 200, 300, 300, 200, 380, 350, 280, 230, 180],
//             borderColor: '#007bff',
//             backgroundColor: 'rgba(0, 123, 255, 0.2)',
//             tension: 0.4,
//         },
//         {
//             label: 'Supervisor',
//             data: [0, 130, 170, 200, 220, 280, 200, 320, 290, 250, 220, 180],
//             borderColor: '#28a745',
//             backgroundColor: 'rgba(40, 167, 69, 0.2)',
//             tension: 0.4,
//         },
//         {
//             label: 'Admin',
//             data: [0, 90, 80, 200, 140, 160, 200, 210, 190, 170, 200, 180],
//             borderColor: '#dc3545',
//             backgroundColor: 'rgba(220, 53, 69, 0.2)',
//             tension: 0.4,
//         },
//     ],
// };

// function FormSubmit(props) {
//     const [datastaffall, setDatastaffall] = useState([
//         { id: 1, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
//         { id: 2, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
//         { id: 3, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
//         { id: 4, name: "aves", staff_id: "12345", department: "dev", destignation: "dev", role: "dev" },
//     ]);

//     const fetchDatanextprestaffall = () => {
//         axios
//             .get(`your_url/admin/Managpermission/index?page=$`)
//             .then((res) => {
//                 // setDatastaffall(videosData);
//             })
//             .catch((err) => {
//                 console.log(err);
//             });
//     };

//     useEffect(() => {
//         fetchDatanextprestaffall();
//     }, []);

//     const handleViewFile = (id) => {
//         // Logic for viewing the file, e.g., navigating to a page or opening a modal
//         window.location.href = `/view-details/${id}`;
//     };

//     const chartOptions = {
//         responsive: true,
//         plugins: {
//             legend: { position: 'top' },
//             title: { display: true, text: 'Dashboard Metrics' },
//         },
//     };
//     return (
//         <>
//             <div className="m-0 p-0">
//                 <div className="container-fluid">
//                     <section className="section mt-4">
//                         <div className="dashboard-card" style={{ width: '100%' }}>
//                             <h5 className="text-start">TOTAL FORM SUBMIT</h5>
//                             <Line data={lineChartData} options={chartOptions} />
//                         </div>

//                         <div className="card mt-4">
//                             <div className="col-lg-12 row py-3">
//                                 <div className="col-6 col-sm-6 col-lg-6">
//                                     <h3>FORM SUBMIT</h3>
//                                 </div>
//                                 <div className="col-6 col-sm-6 col-lg-6 text-end">
//                                     <form className="d-flex justify-content-end">
//                                         <div className="position-relative w-75">
//                                             <FaSearch
//                                                 className="position-absolute text-muted"
//                                                 style={{
//                                                     top: "50%",
//                                                     left: "9px",
//                                                     transform: "translateY(-50%)",
//                                                     pointerEvents: "none", // Makes the icon unclickable
//                                                 }}
//                                             />
//                                             <input
//                                                 className="form-control bg-light ps-4"
//                                                 type="text"
//                                                 placeholder="Search here..."
//                                             />
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>

//                             <div className="col-lg-12 ">
//                                 <div className="card">
//                                     <div className="p-2 text-start iq-card-body">
//                                         <Table>
//                                             <thead className="p-1">
//                                                 <tr className="title">
//                                                     <th>#</th>
//                                                     <th>NAMES</th>
//                                                     <th>STAFF ID</th>
//                                                     <th>DEPARTMENT</th>
//                                                     <th>DESTIGNATION</th>
//                                                     <th>ROLES</th>
//                                                     <th>VIEW</th>
//                                                 </tr>
//                                             </thead>
//                                             {datastaffall?.map((item) => (
//                                                 <tbody key={item?.id}>
//                                                     <tr>
//                                                         <td>{item?.id}</td>
//                                                         <td>{item?.name}</td>
//                                                         <td>{item?.staff_id}</td>
//                                                         <td>{item?.department}</td>
//                                                         <td>{item?.destignation}</td>
//                                                         <td>{item?.role}</td>
//                                                         <td>
//                                                             <FaEye
//                                                                 onClick={() => handleViewFile(item?.id)}
//                                                                 title="View File"
//                                                             />
//                                                         </td>
//                                                     </tr>
//                                                 </tbody>
//                                             ))}
//                                         </Table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     </section>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default FormSubmit;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { FaEye, FaSearch } from "react-icons/fa";
import { Line } from "react-chartjs-2";

function FormSubmit() {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });
    const [datastaffall, setDatastaffall] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    // Fetch data from the API
    const fetchChartData = async () => {
        try {
            const response = await axios.get("https://api.avessecurity.com/api/monthlyData/get-formData");
            const { labels, data } = response.data;

            // Prepare dynamic datasets
            const datasets = [];
            Object.keys(data).forEach((module) => {
                const roles = data[module];
                Object.keys(roles).forEach((role) => {
                    datasets.push({
                        label: `${module} (${role})`,
                        data: roles[role],
                        borderColor: getRandomColor(),
                        backgroundColor: getRandomColor(0.2),
                        tension: 0.4,
                    });
                });
            });

            setChartData({ labels, datasets });
        } catch (error) {
            console.error("Error fetching chart data:", error);
        }
    };

    // Fetch staff data
    const fetchStaffData = async () => {
        try {
            const response = await axios.get(`your_url/admin/Managpermission/index?page=1`);
            setDatastaffall(response.data); // Update this according to your API response
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    useEffect(() => {
        fetchChartData();
        fetchStaffData();
    }, []);

    // Helper function to generate random colors
    const getRandomColor = (alpha = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredStaff = datastaffall.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="m-0 p-0">
            <div className="container-fluid">
                <section className="section mt-4">
                    {/* Chart */}
                    <div className="dashboard-card" style={{ width: "100%" }}>
                        <h5 className="text-start">TOTAL FORM SUBMIT</h5>
                        <Line
                            data={chartData}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: { position: "top" },
                                    title: { display: true, text: "Dashboard Metrics" },
                                },
                            }}
                        />
                    </div>

                    {/* Table */}
                    <div className="card mt-4">
                        <div className="col-lg-12 row py-3">
                            <div className="col-6 col-sm-6 col-lg-6">
                                <h3>FORM SUBMIT</h3>
                            </div>
                            <div className="col-6 col-sm-6 col-lg-6 text-end">
                                <form className="d-flex justify-content-end">
                                    <div className="position-relative w-75">
                                        <FaSearch
                                            className="position-absolute text-muted"
                                            style={{
                                                top: "50%",
                                                left: "9px",
                                                transform: "translateY(-50%)",
                                                pointerEvents: "none",
                                            }}
                                        />
                                        <input
                                            className="form-control bg-light ps-4"
                                            type="text"
                                            placeholder="Search here..."
                                            value={searchTerm}
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="p-2 text-start iq-card-body">
                                    <Table>
                                        <thead className="p-1">
                                            <tr className="title">
                                                <th>#</th>
                                                <th>NAMES</th>
                                                <th>STAFF ID</th>
                                                <th>DEPARTMENT</th>
                                                <th>DESTIGNATION</th>
                                                <th>ROLES</th>
                                                <th>VIEW</th>
                                            </tr>
                                        </thead>
                                        {filteredStaff.map((item, index) => (
                                            <tbody key={item?.id}>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.staff_id}</td>
                                                    <td>{item?.department}</td>
                                                    <td>{item?.destignation}</td>
                                                    <td>{item?.role}</td>
                                                    <td>
                                                        <FaEye
                                                            onClick={() =>
                                                                (window.location.href = `/view-details/${item?.id}`)
                                                            }
                                                            title="View File"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FormSubmit;

import React, { useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

function EditManagerole() {
  const location = useLocation();
  const { id } = useParams();
  const userRole = location.state?.userData; // Assuming role data is passed via location.state
  console.log("role data", userRole)
  console.log("roleId", id);
  console.log("user role", userRole);

  // Initialize the state based on userRole permissions
  const [checked, setChecked] = useState({
    view: userRole?.permissions?.view || false,
    edit: userRole?.permissions?.update || false,
    create: userRole?.permissions?.create || false,
    delete: userRole?.permissions?.delete || false,
  });

  const [loading, setLoading] = useState(false); // For API call status

  const handleCheckboxChange = (buttonName) => {
    setChecked((prev) => ({
      ...prev,
      [buttonName]: !prev[buttonName],
    }));
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const token = localStorage.getItem("access_token");
    console.log("token", token)
    if (!token) {
      alert("Unauthorized: No access token found!");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.put(
        `https://api.avessecurity.com/api/Roles/updateRole/${userRole._id}`,

        {
          permissions: {
            view: checked.view,
            create: checked.create,
            update: checked.edit, // 'edit' corresponds to 'update' in API
            delete: checked.delete,
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Set Bearer token
            "Content-Type": "application/json",
          }
        }
      );

      console.log("Response:", response.data);
      alert("Permissions updated successfully!");
    } catch (error) {
      console.error("Error updating permissions:", error);
      alert("Failed to update permissions. Please try again.");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="m-0 p-0">
        <div className="container-fluid">
          <section className="section mt-4">
            <div className="card">
              <div className="col-lg-12 row py-3">
                <div className="col-5 col-sm-5 col-lg-5">
                  <h3>{`Role : ${userRole?.name}`}</h3>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="p-2 text-start iq-card-body">
                    <div className="button-wrapper mt-3">
                      <button
                        className={`styled-button ${checked.view ? "checked" : ""}`}
                        onClick={() => handleCheckboxChange("view")}
                      >
                        <span>VIEW</span>
                        <input
                          type="checkbox"
                          className="styled-checkbox"
                          checked={checked.view}
                          onChange={() => handleCheckboxChange("view")}
                          style={{ display: "none" }}
                        />
                      </button>
                      <button
                        className={`styled-button ${checked.edit ? "checked" : ""}`}
                        onClick={() => handleCheckboxChange("edit")}
                      >
                        <span>EDIT</span>
                        <input
                          type="checkbox"
                          className="styled-checkbox"
                          checked={checked.edit}
                          onChange={() => handleCheckboxChange("edit")}
                          style={{ display: "none" }}
                        />
                      </button>
                      <button
                        className={`styled-button ${checked.create ? "checked" : ""}`}
                        onClick={() => handleCheckboxChange("create")}
                      >
                        <span>CREATE</span>
                        <input
                          type="checkbox"
                          className="styled-checkbox"
                          checked={checked.create}
                          onChange={() => handleCheckboxChange("create")}
                          style={{ display: "none" }}
                        />
                      </button>
                      <button
                        className={`styled-button ${checked.delete ? "checked" : ""}`}
                        onClick={() => handleCheckboxChange("delete")}
                      >
                        <span>DELETE</span>
                        <input
                          type="checkbox"
                          className="styled-checkbox"
                          checked={checked.delete}
                          onChange={() => handleCheckboxChange("delete")}
                          style={{ display: "none" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end" style={{ width: "88%" }}>
                  <button
                    className="styled-button"
                    onClick={handleSaveChanges}
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save Changes"}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default EditManagerole;

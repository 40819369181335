import React, { useState } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { FaSearch, FaCheckCircle } from "react-icons/fa";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function OnlineStatus() {
    const [datastaffall, setDatastaffall] = useState([
        { id: 1, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
        { id: 2, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "offline" },
        { id: 3, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "deactivate" },
        { id: 4, name: "Aves", staff_id: "12345", department: "Dev", destignation: "Dev", role: "Dev", status: "online" },
    ]);

    const fetchDatanextprestaffall = async (page = 1) => {
        try {
            const res = await axios.get(`your_url/admin/Onlinestatus/index`, {
                params: { page },
            });
            // Process response data when available
            // setDatastaffall(res.data);
        } catch (err) {
            console.error("Error fetching data:", err);
        }
    };

    const onlineStatusData = {
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        datasets: [
            {
                label: "Online",
                data: [20, 25, 30, 35, 40, 45, 50],
                backgroundColor: "#4caf50",
            },
            {
                label: "Offline",
                data: [15, 20, 25, 30, 35, 40, 45],
                backgroundColor: "#f44336",
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: "Dashboard Metrics" },
        },
    };

    const renderStatus = (status) => {
        const statusClass = {
            online: "bg-success",
            offline: "bg-danger",
            deactivate: "bg-warning",
        }[status] || "bg-secondary";

        return (
            <span className={`d-inline-flex align-items-center px-3 py-1 rounded-pill ${statusClass} text-white`}>
                <FaCheckCircle className="me-2" />
                {status.charAt(0).toUpperCase() + status.slice(1)}
            </span>
        );
    };

    return (
        <div className="container-fluid">
            <section className="section mt-4">
                <div className="row g-4">
                    <div className="col-lg-4">
                        <div className="dashboard-onlinestatus">
                            <h5>Online Status</h5>
                            <Bar
                                data={onlineStatusData}
                                options={chartOptions}
                                style={{ width: "104%", height: "100%" }} // Fill the parent container
                            />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="dashboard-section">
                            <h5>Calendar</h5>
                            <Calendar className="custom-calendar" />
                        </div>
                    </div>
                    <div className="col-lg-4 dashboard-section">
                        <div>
                            <label htmlFor="startDate" className="form-label fw-bold mt-3">
                                SELECT START DATE
                            </label>
                            <input type="date" className="form-control" id="startDate" name="startDate" />
                        </div>
                        <div>
                            <label htmlFor="endDate" className="form-label fw-bold mt-3">
                                SELECT END DATE
                            </label>
                            <input type="date" className="form-control" id="endDate" name="endDate" />
                        </div>
                        <div className="text-center mt-5">
                            <button type="button" className="btn btn-dark rounded-pill">
                               SELECT
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card mt-4">
                    <div className="row py-3">
                        <div className="col-md-6">
                            <h3>ONLINE STATUS</h3>
                        </div>
                        <div className="col-md-6 text-end">
                            <form className="d-flex justify-content-end">
                                <div className="position-relative w-75">
                                    <FaSearch
                                        className="position-absolute text-muted"
                                        style={{
                                            top: "50%",
                                            left: "9px",
                                            transform: "translateY(-50%)",
                                            pointerEvents: "none",
                                        }}
                                    />
                                    <input className="form-control bg-light ps-4" type="text" placeholder="Search here..." />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="card-body">
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Staff ID</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datastaffall.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.staff_id}</td>
                                        <td>{item.department}</td>
                                        <td>{item.destignation}</td>
                                        <td>{item.role}</td>
                                        <td>{renderStatus(item.status)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OnlineStatus;
